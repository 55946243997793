import resetLikes from './likes';

interface Data {
  itemCount: number;
  lessonId?: string;
}

interface Item {
  content: string;
  deleted?: boolean;
}

$(document).on('turbolinks:load', () => {
  const itemsPath = $('#itemsPath').attr('value');
  const lessonId = $('#lessonId').attr('value');
  const seeMore = $('a.viewMore');

  const fetchItems = (event: Event) => {
    event.preventDefault();
    const itemsList = $('.itemsList');
    const itemCount = () => itemsList.children().length;
    const data: Data = { itemCount: itemCount() };

    if (lessonId) {
      data.lessonId = lessonId;
    }

    $.ajax({
      url: itemsPath,
      data,
      dataType: 'json',
      method: 'get',
      success: (response) => {
        response.items.forEach((item: Item) => (
          itemsList.append(`<li ${item.deleted ? 'class="deleted"' : ''}>${item.content}</li>`)
        ));
        if (itemCount() >= response.totalItems) {
          seeMore.remove();
        }
        resetLikes();
      },
      error: (message) => {
        console.error(message);
      }
    });
  };

  seeMore.on('click', fetchItems);
});
