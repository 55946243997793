$(document).on('turbolinks:load', () => {
  const panels = Array.from(document.querySelectorAll("[data-collapsible='true']"));
  const paginations = Array.from(document.getElementsByName('pagination')) as HTMLInputElement[];

  if (panels.length === 0) {
    return;
  }

  const toggleBody = (arrow: HTMLDivElement) => {
    // Track back to the contentPanel to update the classlist
    const parentPanel = arrow.closest("[data-collapsible='true']");
    if (parentPanel?.classList.contains('collapsed')) {
      parentPanel.classList.remove('collapsed');
      arrow.classList.add('down');
      return;
    }
    parentPanel?.classList.add('collapsed');
    arrow.classList.remove('down');
  };

  const collapsiblePanel = (panel: HTMLDivElement) => {
    if (panel.dataset.collapsibledefault === 'true') {
      panel.classList.add('collapsed');
    }

    const titleBar = panel.querySelector('.collapsible');
    if (!titleBar) {
      return;
    }
    const title = titleBar.querySelector('.title');
    if (!title) {
      return;
    }

    // If page is being paginated, we won't collapse the panel
    paginations.forEach((pagination) => {
      if (pagination.value === title.textContent?.toLocaleLowerCase()) {
        panel.classList.remove('collapsed');
      }
    });

    const arrow = title.querySelector('.arrow');
    if (!arrow) {
      return;
    }

    if (!panel.classList.contains('collapsed')) {
      arrow.classList.add('down');
    }

    titleBar.addEventListener('click', (event) => {
      const eventTarget = event.target as HTMLElement;
      const updatedArrow = title.querySelector('.arrow');
      if (updatedArrow && !eventTarget.classList.contains('button')) {
        event.stopImmediatePropagation();
        toggleBody(updatedArrow as HTMLDivElement);
      }
    });
  };

  panels.forEach((panel) => collapsiblePanel(panel as HTMLDivElement));
});
