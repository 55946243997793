import safeParseInt from '../utils/safeParseInt';

$(document).on('turbolinks:load', () => {
  const updateCounter = (field: HTMLInputElement) => {
    const { charlimit, charlimitoverflow, charlimitwarning } = field.dataset;
    const actualLength = field.value.length;
    const charLimitInt = safeParseInt(charlimit);
    const charLimitWarningInt = safeParseInt(charlimitwarning);
    const pElement = document.createElement('p');
    pElement.textContent = `${actualLength} out of ${charLimitInt} characters`;
    pElement.style.textAlign = 'right';

    const divElement = document.createElement('div');
    divElement.className = 'charCounter';
    divElement.appendChild(pElement);
    if (field.nextElementSibling?.className === divElement.className) {
      field.nextElementSibling.remove();
    }
    field.after(divElement);

    if (actualLength > charLimitWarningInt && charLimitWarningInt !== 0) {
      pElement.style.color = '#f00';
    }
    if (charlimitoverflow === 'false' && actualLength === charLimitInt) {
      field.maxLength = charLimitInt;
    }
  };

  $('textarea').each((_index, textarea: HTMLInputElement) => {
    if (!textarea.attributes.getNamedItem('data-charlimit')) {
      return;
    }

    updateCounter(textarea);
    textarea.addEventListener('keyup', (event) => updateCounter(event.target as HTMLInputElement));
  });
});
