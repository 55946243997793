// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Dependencies
require('@rails/activestorage').start();
require('@rails/actiontext');
require('turbolinks').start();
const Trix = require('trix');

// Local code
require('turbomorph');

// Enrichment
require('enrichment/charCounter');
require('enrichment/checkbox');
require('enrichment/collapsible');
require('enrichment/countryStatePicker');
require('enrichment/courseware');
require('enrichment/datepicker');
require('enrichment/groupThemeColor');
require('enrichment/itemLoader');
require('enrichment/likes');
require('enrichment/survey');
require('enrichment/toast');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// window.I18n = I18n;

['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].forEach((tagName, i) => {
  Trix.config.blockAttributes[`heading${(i + 1)}`] = { tagName, terminal: true, breakOnReturn: true, group: false };
});
