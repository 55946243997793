$(document).on('turbolinks:load', () => {
  // Get all the checkboxes with `datetimecheckboxes` class to add EventListener
  const checkBoxes = Array.from(
    document.getElementsByClassName('datetimecheckboxes') as HTMLCollectionOf<HTMLInputElement>
  );

  // Pick all `datetimepicker` fields to respond to checkboxes `change` events
  // and disable or enable field
  const inputFields = Array.from(
    document.getElementsByClassName('datetimepicker form-control input') as HTMLCollectionOf<HTMLInputElement>
  );

  // Pick `datetimepicker` to modify the field values
  const inputValues = Array.from(
    document.getElementsByClassName('datetimepicker') as HTMLCollectionOf<HTMLInputElement>
  );

  checkBoxes.forEach((checkBox) => {
    const expectedName = `announcement[${checkBox.value}]`;
    const expectedFieldName = `${checkBox.value}_fields`;
    const singleInput = inputValues.find((inputValue) => inputValue.name === expectedName);
    const onChange = () => {
      inputFields.forEach((inputField) => {
        if (inputField.parentElement?.className === expectedFieldName) {
          if (checkBox.checked) {
            inputField.disabled = false;
          } else {
            inputField.disabled = true;
            inputField.value = '';
            if (singleInput) {
              singleInput.value = '';
            }
          }
        }
      });
    };

    onChange();
    checkBox.addEventListener('change', onChange);
  });
});
