import Turbolinks from 'turbolinks';
import morphdom from 'morphdom';

(() => {
  // @ts-ignore
  Turbolinks.SnapshotRenderer.prototype.assignNewBody = function assignNewBody() {
    console.debug('Replacing content using morphdom');
    return morphdom(document.body, this.newBody, {});
  };

  // @ts-ignore
  Turbolinks.View.prototype.renderError = function renderError(error, callback) {
    return this.renderSnapshot(error, false, callback);
  };
}).call(this);
