$(document).on('turbolinks:load', () => {
  $('input[type=checkbox]').on('change', (event) => {
    const question = $(event.target).attr('data_question');
    const maxSelectable = parseInt($(event.target).attr('data_max_selectable') as string, 10);
    const selectedAmount = $(`input[type=checkbox][data_question=${question}]:checked`).length;
    const inputsNotChecked = $(`input[type=checkbox][data_question=${question}]:not(:checked)`);

    if (selectedAmount === maxSelectable) {
      inputsNotChecked.attr('disabled', 'true');
    }

    if (selectedAmount < maxSelectable) {
      inputsNotChecked.removeAttr('disabled');
    }
  });

  $('input[type=submit]').on('click', () => {
    $('.multipleChoice[required]').each((_index, question) => {
      const questionId = $(question).attr('data_question');
      if ($(`input[type=checkbox][data_question=${questionId}]:checked`).length > 0) {
        $(`input[type=radio][name="questions[${questionId}]"]`).removeAttr('required');
      }
    });
  });

  $('input[type=checkbox]').on('change', (event) => {
    const optionId = $(event.target).attr('value');
    $(`li[data_dependency="${optionId}"]`).toggle();
  });
});
