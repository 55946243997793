import { CountryData, allCountries } from 'country-region-data';

$(document).on('turbolinks:load', () => {
  const countryElement = document.getElementById('country') as HTMLSelectElement;
  const defaultCountry = document.getElementById('defaultCountry') as HTMLInputElement;
  const defaultState = document.getElementById('defaultState') as HTMLInputElement;
  const stateElement = document.getElementById('state') as HTMLSelectElement;

  if (!countryElement || !defaultCountry) {
    return;
  }

  countryElement.disabled = false;

  allCountries.forEach((country, index) => {
    const countryName = country[0];
    const defaultName = defaultCountry.value;
    countryElement.options[index] = new Option(countryName, countryName, countryName === defaultName, countryName === defaultName);
  });

  if (!stateElement) {
    return;
  }

  stateElement.disabled = false;

  const getCountryByName = (name: string) => {
    const country = allCountries.find((countryData) => countryData[0] === name);
    if (country === undefined) {
      return allCountries[0];
    }
    return country;
  };

  const createStateDropDown = (country: CountryData) => {
    [countryElement.value] = country;
    const states = country[2];
    states.forEach((state, index) => {
      stateElement.options[index] = new Option(state[0], state[0]);
    });
    const stateSelectedIndex = states.findIndex((state) => state[0] === defaultState.value);
    stateElement.selectedIndex = stateSelectedIndex === -1 ? 0 : stateSelectedIndex;
  };

  const changeStates = (name: string) => {
    stateElement.innerHTML = '';
    const country = getCountryByName(name);
    createStateDropDown(country);
  };

  // Get user's saved country or fetch from the first element from dropdown
  const countryName = getCountryByName(defaultCountry.value);
  createStateDropDown(countryName);
  countryElement.addEventListener('change', (event) => changeStates((event.target as HTMLSelectElement).value));
});
