import flatpickr from 'flatpickr';

$(document).on('turbolinks:load', () => {
  const el = document.getElementsByClassName('datetimepicker');
  flatpickr(el, {
    altInput: true,
    dateFormat: 'Y-m-d H:i',
    enableTime: true,
    minuteIncrement: 1
  });
});
