const handleClick = (unit: any) => {
  $(unit).find('.lessons').each((_, lessonsElement) => {
    const lessons = $(lessonsElement);
    const openLessons = $('.units li .lessons.open');
    if (openLessons.length > 0) {
      // Slide-up any open lesson lists and slide-down the new lesson list
      openLessons.first().slideUp(160, () => {
        openLessons.addClass('closed');
        openLessons.removeClass('open');
        lessons.slideDown(160, () => {
          lessons.removeClass('closed');
          lessons.addClass('open');
        });
      });
    } else {
      // Slide-down the new lesson list
      lessons.slideDown(160, () => {
        lessons.removeClass('closed');
        lessons.addClass('open');
      });
    }
  });
};

$(document).on('turbolinks:load', () => {
  $('.units li.unit').each((_, unitElement) => {
    const unit = $(unitElement);
    unit.children('.title').children('a').on('click', (event) => {
      event.preventDefault();
      handleClick(unit);
    });
  });
});
