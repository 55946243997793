const url = (path: string) => {
  const actionUrl = path.split('/like');
  actionUrl.splice(-1, 0, '/api/v1/like');
  return actionUrl.join('');
};

const likeInputs = '.likeable input[type="submit"]';

const enableLikes = () => {
  $(likeInputs).each((_, submitElement) => {
    const submit = $(submitElement);
    submit.on('click', (event) => {
      event.preventDefault();
      const forms = submit.closest('form');
      if (forms && forms.length > 0) {
        forms.each((__, formElement) => {
          const form = $(formElement);
          $.ajax({
            url: url(form.attr('action') || ''),
            data: form.serialize(),
            dataType: 'json',
            method: 'post',
            beforeSend: (xhr) => {
              const token = $('#bearer_token').attr('value');
              xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            },
            success: (response, ___, xhr) => {
              if (Object.prototype.hasOwnProperty.call(response, 'error')) {
                console.error(response.error);
              } else {
                (form[0] as any).elements.authenticity_token.value = $('meta[name=csrf-token]').attr('content');
                const location = xhr.getResponseHeader('Location');
                form.attr('action', location);
                const methodFieldElement = form.find('input[name="_method"]');
                const methodField = methodFieldElement.length === 0
                  ? $('<input>').attr({ type: 'hidden', name: '_method', value: 'post' }).prependTo(form)
                  : $(methodFieldElement);
                if (methodField.val() === 'delete') {
                  submit.attr('method', 'post');
                  form.find('svg.icon').removeClass('active');
                  methodField.val('post');
                } else if (methodField.val() === 'post') {
                  form.find('svg.icon').addClass('active');
                  methodField.val('delete');
                }
              }
            },
            error: (response) => {
              console.error(response.statusText);
              if (response.status === 401) {
                window.location.href = '/?alert=like could not be created.';
              }
            }
          });
        });
      }
    });
  });
};

const likes = () => {
  $(likeInputs).each((_, submitElement) => {
    $(submitElement).off('click');
  });
  enableLikes();
};

$(document).on('turbolinks:load', enableLikes);

export default likes;
