/**
 * Parse an integer or return a safe default
 *
 * This function converts a string value to integer.
 * If the string cannot be parsed as a number, a default value will be returned. The default value is 0 unless specified.
 *
 * @param value A string to convert into a number.
 */
function safeParseInt(value: string | null | undefined, defaultValue: number = 0): number {
  return parseInt(value || '', 10) || defaultValue;
}

export default safeParseInt;
